
import { defineComponent } from "vue";
import BannerPreview from "@/components/banners/BannerPreview.vue";
import { Navigation, ResponsiveManager, Store } from "@/controllers/common";
import Banner, { BannerRecord } from "@/models/banner";
import { ScreenType } from "@/models/common";
import BannersController from "@/controllers/banners";

import Authentication from "@/controllers/auth";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Banners",
  setup() {
    useMeta({
      title: "Banners",
      description: "Administra los banners del sitio web.",
    });
  },
  components: {
    BannerPreview,
  },
  created() {
    if (this.currentUser) {
      this.fetchBanners();
    }
  },
  data() {
    return {
      fetchingData: false,
      banners: [] as Array<Banner>,
    };
  },
  methods: {
    addBanner(): void {
      Navigation.goTo("AddBanner");
    },
    editBanner(data: Banner): void {
      Store.update("edition/storeBanner", data);
      Navigation.goTo("EditBanner");
    },
    async fetchBanners(): Promise<void> {
      this.fetchingData = true;
      const bannersData = await BannersController.fecthAll(this.currentUser);
      this.fetchingData = false;
      if (bannersData) {
        const data = bannersData as BannerRecord[];
        let banners: Banner[] = [];
        for (const banner of data) {
          banners.push(new Banner(banner, banner.reference));
        }
        this.banners = banners;
      }
    },
    async deleteBanner(bannerId: string): Promise<void> {
      if (this.banners.length > 1) {
        this.fetchingData = true;
        await BannersController.deleteBanner(bannerId, this.currentUser);
        this.fetchBanners();
      } else {
        const notification = new PopUpMessage({
          title: "No es posible",
          message: "No se puede eliminar el banner, la cantidad mínima es 1.",
          type: NotificationType.Warning,
        });
        notification.show();
      }
    },
  },
  computed: {
    screenSize(): ScreenType {
      return ResponsiveManager.retrieveScreenSize();
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
});
