<template>
  <h1 class="global-title">Banners</h1>
  <a-spin :spinning="fetchingData">
    <div
      class="banners-list splitted animated-container"
      :class="{ loading: fetchingData }"
    >
      <BannerPreview
        v-for="(banner, index) in banners"
        :key="index"
        :data="banner"
        :device="screenSize"
        @edit-banner="editBanner(banner)"
        @delete-banner="deleteBanner"
      />
      <BannerPreview
        :isAction="true"
        :device="screenSize"
        v-if="banners.length < 6"
        v-show="!fetchingData"
        @add-banner="addBanner"
      />
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BannerPreview from "@/components/banners/BannerPreview.vue";
import { Navigation, ResponsiveManager, Store } from "@/controllers/common";
import Banner, { BannerRecord } from "@/models/banner";
import { ScreenType } from "@/models/common";
import BannersController from "@/controllers/banners";

import Authentication from "@/controllers/auth";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Banners",
  setup() {
    useMeta({
      title: "Banners",
      description: "Administra los banners del sitio web.",
    });
  },
  components: {
    BannerPreview,
  },
  created() {
    if (this.currentUser) {
      this.fetchBanners();
    }
  },
  data() {
    return {
      fetchingData: false,
      banners: [] as Array<Banner>,
    };
  },
  methods: {
    addBanner(): void {
      Navigation.goTo("AddBanner");
    },
    editBanner(data: Banner): void {
      Store.update("edition/storeBanner", data);
      Navigation.goTo("EditBanner");
    },
    async fetchBanners(): Promise<void> {
      this.fetchingData = true;
      const bannersData = await BannersController.fecthAll(this.currentUser);
      this.fetchingData = false;
      if (bannersData) {
        const data = bannersData as BannerRecord[];
        let banners: Banner[] = [];
        for (const banner of data) {
          banners.push(new Banner(banner, banner.reference));
        }
        this.banners = banners;
      }
    },
    async deleteBanner(bannerId: string): Promise<void> {
      if (this.banners.length > 1) {
        this.fetchingData = true;
        await BannersController.deleteBanner(bannerId, this.currentUser);
        this.fetchBanners();
      } else {
        const notification = new PopUpMessage({
          title: "No es posible",
          message: "No se puede eliminar el banner, la cantidad mínima es 1.",
          type: NotificationType.Warning,
        });
        notification.show();
      }
    },
  },
  computed: {
    screenSize(): ScreenType {
      return ResponsiveManager.retrieveScreenSize();
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
});
</script>

<style scoped>
.splitted {
  flex-flow: column;
}

.banners-list.loading {
  min-height: 80vh;
  grid-template-rows: auto auto auto 1fr;
}

@media only screen and (min-width: 768px) {
  .banners-list.splitted {
    grid-template-columns: repeat(2, calc(50% - 1rem));
    column-gap: 2rem;
    display: grid;
  }
}
</style>
