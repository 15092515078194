export interface BannerData {
  title: string;
  desktop: string;
  desktopFile?: File;
  tablet: string;
  tabletFile?: File;
  mobile: string;
  mobileFile?: File;
}

export interface BannerRecord {
  title: string;
  desktop: string;
  tablet: string;
  mobile: string;
  reference: string;
}

export default class Banner {
  title: string;
  desktop: string;
  desktopFile?: File;
  tablet: string;
  tabletFile?: File;
  mobile: string;
  mobileFile?: File;
  reference: string;

  constructor(
    {
      title,
      desktop,
      desktopFile,
      tablet,
      tabletFile,
      mobile,
      mobileFile,
    }: BannerData,
    reference: string
  ) {
    this.title = title;
    this.desktop = desktop;
    this.desktopFile = desktopFile;
    this.tablet = tablet;
    this.tabletFile = tabletFile;
    this.mobile = mobile;
    this.mobileFile = mobileFile;
    this.reference = reference;
  }

  get data(): BannerData {
    return {
      title: this.title,
      desktop: this.desktop,
      desktopFile: this.desktopFile,
      tablet: this.tablet,
      tabletFile: this.tabletFile,
      mobile: this.mobile,
      mobileFile: this.mobileFile,
    };
  }
}
