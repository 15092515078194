<template>
  <div class="bpw-cnt" :class="{ mobile: isMobile, tablet: isTablet }">
    <div
      class="preview ratio-container"
      :class="{ mobile: isMobile, tablet: isTablet }"
    >
      <img
        :src="mediaSrc"
        :alt="data.title"
        :title="data.title"
        class="prev-pic ratio-fill"
        v-if="showImage"
        @click="editBanner"
      />
      <video
        :src="mediaSrc"
        :title="data.title"
        class="prev-pic ratio-fill"
        v-if="showVideo"
        @click="editBanner"
      />
      <AddBlock
        text="Agregar Banner"
        class="ratio-fill"
        v-if="isAction"
        @click="addBanner"
      />
    </div>
    <a-popconfirm
      placement="left"
      title="¿Está seguro de que desea eliminar este banner?"
      ok-text="Sí, eliminar"
      cancel-text="No, mantener"
      @confirm="deleteBanner"
      v-if="showDelete"
    >
      <div class="action">
        <img src="@/assets/actions/delete-icon.svg" alt="Eliminar Banner" />
      </div>
    </a-popconfirm>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import AddBlock from "@/components/general/AddBlock.vue";
import { ScreenType } from "@/models/common";
import { BannerRecord } from "@/models/banner";

export default defineComponent({
  name: "BannerPreview",
  components: {
    AddBlock,
  },
  props: {
    isAction: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object as PropType<BannerRecord>,
      default: () => {
        return {};
      },
    },
    device: {
      type: Number as PropType<ScreenType>,
      required: true,
    },
  },
  emits: ["addBanner", "editBanner", "deleteBanner"],
  computed: {
    mediaSrc(): string {
      switch (this.device) {
        case ScreenType.mobile:
          return this.data.mobile;
        case ScreenType.tablet:
          return this.data.tablet;
        case ScreenType.smallDesktop:
          return this.data.tablet;
        default:
          return this.data.desktop;
      }
    },
    showImage(): boolean {
      return !this.isAction && !this.mediaSrc.includes(".mp4");
    },
    showVideo(): boolean {
      return !this.isAction && this.mediaSrc.includes(".mp4");
    },
    showDelete(): boolean {
      return this.showAction && !this.isAction;
    },
    isMobile(): boolean {
      return this.device === ScreenType.mobile;
    },
    isTablet(): boolean {
      return (
        this.device === ScreenType.tablet ||
        this.device === ScreenType.smallDesktop
      );
    },
  },
  methods: {
    addBanner(): void {
      this.$emit("addBanner");
    },
    editBanner(): void {
      this.$emit("editBanner");
    },
    deleteBanner(): void {
      this.$emit("deleteBanner", this.data.reference);
    },
  },
});
</script>

<style scoped>
.bpw-cnt {
  display: flex;
}

.bpw-cnt.mobile,
.bpw-cnt.tablet {
  position: relative;
}

.bpw-cnt.mobile .action,
.bpw-cnt.tablet .action {
  padding: 0.5rem;
  top: 0;
  right: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  border-bottom-left-radius: 2rem;
}

.ratio-container.mobile {
  --aspect-ratio: calc(3 / 4 * 100%);
  width: 100%;
}

.ratio-container.tablet {
  --aspect-ratio: calc(360 / 683 * 100%);
  width: 100%;
}

.preview {
  width: calc(100% - 48px);
}

.prev-pic {
  cursor: pointer;
  object-fit: cover;
}

.add-action {
  cursor: pointer;
  background: var(--c-concrete);
}

.action {
  width: 48px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.action img {
  cursor: pointer;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .preview {
    width: calc(100% - 52px);
  }

  .action {
    width: 52px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .preview {
    width: calc(100% - 60px);
  }

  .action {
    width: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
</style>
