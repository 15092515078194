<template>
  <div class="add-block">
    <div class="add-cnt">
      <img src="@/assets/actions/add-icon.svg" :alt="text" class="icon" />
      <h2 class="text">{{ text }}</h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "AddBlock",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.add-block {
  cursor: pointer;
  background: var(--c-concrete);
  align-items: center;
  justify-content: center;
  display: flex;
}
.add-cnt {
  text-align: center;
}
.icon {
  height: 40px;
  width: 40px;
}
.text {
  margin: 0;
  color: var(--c-mid-gray);
  font-size: 1.2rem;
  font-weight: var(--f-semibold);
}
</style>
