import { BannerData, BannerRecord } from "@/models/banner";
import { FirebaseUser } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import FormData from "form-data";
import { Navigation } from "./common";
import ServiceController from "./service";

export default class BannersController extends ServiceController {
  static async addBanner(
    bannerData: BannerData,
    user: FirebaseUser,
    callback: (progress: number) => void
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("title", bannerData.title);
      for (const [key, value] of Object.entries(bannerData)) {
        if (key.includes("File")) {
          data.append(key, value, (value as File).name);
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        onUploadProgress: (progressEvent) =>
          callback(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          ),
      } as AxiosRequestConfig;
      await axios.post(
        `${BannersController.HOST}/banners/admin/add`,
        data,
        config
      );
      Navigation.goTo("Banners");
    } catch (error) {
      const axiosError = error as AxiosError;
      BannersController.translateError(axiosError);
    }
  }

  static async updateBanner(
    bannerId: string,
    bannerData: BannerData,
    user: FirebaseUser,
    callback: (progress: number) => void
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const data = new FormData();
      data.append("title", bannerData.title);
      for (const [key, value] of Object.entries(bannerData)) {
        if (key.includes("File") && value) {
          data.append(key, value, (value as File).name);
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": `multipart/form-data; boundary=${data.getBoundary}`,
        },
        params: {
          bannerId: bannerId,
        },
        onUploadProgress: (progressEvent) =>
          callback(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          ),
      } as AxiosRequestConfig;
      await axios.put(
        `${BannersController.HOST}/banners/admin/update`,
        data,
        config
      );
      Navigation.goTo("Banners");
    } catch (error) {
      const axiosError = error as AxiosError;
      BannersController.translateError(axiosError);
    }
  }

  static async deleteBanner(
    bannerId: string,
    user: FirebaseUser
  ): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          bannerId: bannerId,
        },
      } as AxiosRequestConfig;
      await axios.delete(
        `${BannersController.HOST}/banners/admin/remove`,
        headers
      );
    } catch (error) {
      const axiosError = error as AxiosError;
      BannersController.translateError(axiosError);
    }
  }

  static async fecthAll(
    user: FirebaseUser
  ): Promise<BannerRecord[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${BannersController.HOST}/banners/admin/all`,
        config
      );
      const banners: BannerRecord[] = response.data.banners;

      return banners;
    } catch (error) {
      const axiosError = error as AxiosError;
      BannersController.translateError(axiosError);
    }
  }
}
