
import { defineComponent, PropType } from "vue";

import AddBlock from "@/components/general/AddBlock.vue";
import { ScreenType } from "@/models/common";
import { BannerRecord } from "@/models/banner";

export default defineComponent({
  name: "BannerPreview",
  components: {
    AddBlock,
  },
  props: {
    isAction: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object as PropType<BannerRecord>,
      default: () => {
        return {};
      },
    },
    device: {
      type: Number as PropType<ScreenType>,
      required: true,
    },
  },
  emits: ["addBanner", "editBanner", "deleteBanner"],
  computed: {
    mediaSrc(): string {
      switch (this.device) {
        case ScreenType.mobile:
          return this.data.mobile;
        case ScreenType.tablet:
          return this.data.tablet;
        case ScreenType.smallDesktop:
          return this.data.tablet;
        default:
          return this.data.desktop;
      }
    },
    showImage(): boolean {
      return !this.isAction && !this.mediaSrc.includes(".mp4");
    },
    showVideo(): boolean {
      return !this.isAction && this.mediaSrc.includes(".mp4");
    },
    showDelete(): boolean {
      return this.showAction && !this.isAction;
    },
    isMobile(): boolean {
      return this.device === ScreenType.mobile;
    },
    isTablet(): boolean {
      return (
        this.device === ScreenType.tablet ||
        this.device === ScreenType.smallDesktop
      );
    },
  },
  methods: {
    addBanner(): void {
      this.$emit("addBanner");
    },
    editBanner(): void {
      this.$emit("editBanner");
    },
    deleteBanner(): void {
      this.$emit("deleteBanner", this.data.reference);
    },
  },
});
