
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import SelectImage from "@/components/common/ImageSelect.vue";

import { BannerData } from "@/models/banner";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Navigation } from "@/controllers/common";
import BannersController from "@/controllers/banners";
import Authentication from "@/controllers/auth";
import { FirebaseUser } from "@/models/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "AddBanner",
  setup() {
    useMeta({
      title: "Agregar Banner",
      description: `Agrega un nuevo banner al corrusel.`,
    });
    return { v$: useVuelidate() };
  },
  components: {
    BaseField,
    BaseButton,
    SelectImage,
  },
  data() {
    return {
      spinnerState: false,
      edit: false,
      bannerData: {} as BannerData,
      reference: "",
      uploadProgress: 0,
    };
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async handleAction(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await BannersController.addBanner(
        this.bannerData,
        this.currentUser,
        (progress) => (this.uploadProgress = progress)
      );
      this.spinnerState = false;
    },
  },
  computed: {
    validForm(): boolean {
      return !this.v$.$invalid;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  validations() {
    const validFormat = () => {
      const allowedImgExt = /(\.jpg|\.jpeg|\.png)$/i;
      const allowedVideoExt = /(\.mp4)$/i;
      const desktopFilename = this.bannerData.desktopFile?.name ?? "";
      const tabletFilename = this.bannerData.tabletFile?.name ?? "";
      const mobileFilename = this.bannerData.mobileFile?.name ?? "";
      const images =
        allowedImgExt.exec(desktopFilename) &&
        allowedImgExt.exec(tabletFilename) &&
        allowedImgExt.exec(mobileFilename);
      const loops =
        allowedVideoExt.exec(desktopFilename) &&
        allowedVideoExt.exec(tabletFilename) &&
        allowedVideoExt.exec(mobileFilename);
      return images || loops;
    };
    return {
      bannerData: {
        title: { required },
        desktopFile: { required, validFormat },
        tabletFile: { required, validFormat },
        mobileFile: { required, validFormat },
      },
    };
  },
});
