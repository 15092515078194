
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import SelectImage from "@/components/common/ImageSelect.vue";

import Banner from "@/models/banner";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Navigation, ResponsiveManager, Store } from "@/controllers/common";
import BannersController from "@/controllers/banners";
import Authentication from "@/controllers/auth";
import { FirebaseUser } from "@/models/users";
import { ScreenType } from "@/models/common";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "AddBanner",
  setup() {
    useMeta({
      title: "Editar Banner",
      description: "Edita uno de los banners existentes.",
    });
    return { v$: useVuelidate() };
  },
  components: {
    BaseField,
    BaseButton,
    SelectImage,
  },
  beforeMount() {
    var banner = Store.getValue("edition/banner") as Banner;
    if (banner) {
      this.bannerData = banner;
      this.reference = banner.reference;
    } else {
      this.$router.replace({ name: "AddBanner" });
    }
  },
  beforeUnmount() {
    Store.update("edition/storeBanner", undefined);
  },
  data() {
    return {
      spinnerState: false,
      bannerData: {} as Banner,
      reference: "",
      uploadProgress: 0,
    };
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async handleAction(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;

      await BannersController.updateBanner(
        this.reference,
        this.bannerData,
        this.currentUser,
        (progress) => (this.uploadProgress = progress)
      );
      this.spinnerState = false;
    },
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    validForm(): boolean {
      return !this.v$.$invalid;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
  validations() {
    const filesValidation = () => {
      if (
        this.bannerData.desktopFile ||
        this.bannerData.tabletFile ||
        this.bannerData.mobileFile
      ) {
        const allowedImgExt = /(\.jpg|\.jpeg|\.png)$/i;
        const allowedVideoExt = /(\.mp4)$/i;
        const desktopFilename = this.bannerData.desktopFile?.name ?? "";
        const tabletFilename = this.bannerData.tabletFile?.name ?? "";
        const mobileFilename = this.bannerData.mobileFile?.name ?? "";
        const images =
          allowedImgExt.exec(desktopFilename) &&
          allowedImgExt.exec(tabletFilename) &&
          allowedImgExt.exec(mobileFilename);
        const loops =
          allowedVideoExt.exec(desktopFilename) &&
          allowedVideoExt.exec(tabletFilename) &&
          allowedVideoExt.exec(mobileFilename);
        return (
          this.bannerData.desktopFile &&
          this.bannerData.tabletFile &&
          this.bannerData.mobileFile &&
          (images || loops)
        );
      } else {
        return true;
      }
    };
    return {
      bannerData: {
        title: { required },
        desktopFile: { filesValidation },
        tabletFile: { filesValidation },
        mobileFile: { filesValidation },
      },
    };
  },
});
